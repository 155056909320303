import type { SiteConfig } from '../lib/config';

export const siteConfigNO: SiteConfig = {
  defaultSiteTitle: 'Renholder - Freska', // Site title.
  siteTitleSuffix: 'Freska',
  siteTitleSeparator: ' | ',
  siteUrl: 'https://www.freska.no',
  siteDescription: 'Bestill en pålitelig renholder fra 429 kr/t.',

  countryCode: 'NO',
  defaultLocale: 'nb',
  localesToPublish: ['nb', 'en'],
  contentfulPublishIn: 'Norway',
  companyName: 'Freska Norway AS',
  companyBusinessId: '916 035 659',
  price: 449,
  currency: 'kr',

  features: {
    apartmentSizeInput: true,
  },

  openingHours: {
    weekdays: '12-15',
  },

  accountFacebook: 'freskanorge',
  accountInstagram: 'freska.no',
  accountTwitter: 'freska_no',

  appStoreUrl: 'https://itunes.apple.com/no/app/freska/id1397364064?mt=8',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=net.freska.freskaapp',

  facebookUrl: 'https://www.facebook.com/freskanorge/',
  instagramUrl: 'https://www.instagram.com/freska.no/',
  twitterUrl: 'https://twitter.com/freska_no',
  linkedInUrl: 'https://www.linkedin.com/company/10164941/',
  whistleblowingUrl: 'https://app.northwhistle.com/welcome/6464c048d5924900087db0c2',

  facebookAppId: null,

  services: ['Home cleaning', 'Office cleaning'],
  cities: ['Oslo', 'Bergen', 'Vestfold', 'Østfold'],

  salesCheckoutRedirection: false,

  urls: {
    production: {
      website: 'https://www.freska.no',
      checkout: 'https://bestill.freska.no',
      d2d: 'https://d2d.freskasales.no',
      tele: 'https://tele.freskasales.no',
    },
    staging: {
      website: 'https://www.freska-staging.no',
      checkout: 'https://bestill.freska-staging.no',
      d2d: 'https://d2d.freskasales-staging.no',
      tele: 'https://tele.freskasales-staging.no',
    },
    development: {
      website: 'https://localhost:8000',
      checkout: 'https://localhost:2099',
      d2d: 'https://localhost:2099',
      tele: 'https://localhost:2099',
    },
  },

  slugs: {
    contact: { en: '', nb: '' },
  },
};
